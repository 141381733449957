import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardInicio from '../pages/DashboardInicio.jsx';
import Comprobantes from '../pages/Comprobantes.jsx';
import Login from '../pages/Login.jsx';
import Layout from './Layout.js';
import Dashboard from '../pages/Dashboard.jsx';
import AlquilerHab from '../pages/AlquilerHab.jsx';
import Ventas from '../pages/Ventas.jsx';
import StocksProductos from '../pages/StocksProductos.jsx';
import IngresosDiario from '../pages/IngresosDiario.jsx';
import Habitaciones from '../pages/Habitaciones.jsx';
const AppRouter = () => (
  <Routes>
    <Route exact path="/" element={<Login />} />
    <Route  element={<Layout />}>
      <Route path="habitaciones" element ={<Habitaciones />} /> 
      <Route path="dashboard" element ={<Dashboard />} />
      <Route path="comprobantes" element={<Comprobantes />} />
      <Route path="alquilerhab" element={<AlquilerHab />} />
      <Route path="ventas" element={<Ventas />} />
      <Route path="stocks" element={<StocksProductos />} />
      <Route path="ingresosdiarios" element={<IngresosDiario />} />
    </Route>
  </Routes>
);

export default AppRouter;
