import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Select, MenuItem, InputLabel, FormControl, Button, Tooltip } from '@mui/material';
import { url } from "./Config";
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import { styled } from '@mui/material/styles';

const getColorByEstado = (estado) => {
  switch (estado) {
    case 1:
      return '#00A885';
    case 2:
      return '#f44336';
    case 3:
      return '#00bcd4';
    default:
      return '#ffffff';
  }
};

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .MuiTooltip-tooltip`]: {
    fontSize: '1.2em',
    padding: '15px',
    maxWidth: '300px',
  },
}));

function Habitaciones() {
  const [ide_eje, set_ide_eje] = useState('');
  const [pisoSeleccionado, setPisoSeleccionado] = useState(0);
  const [pisos, setPisos] = useState([]);
  const [habitaciones, setHabitaciones] = useState([]);
  const [disponibles, setDisponibles] = useState(0);
  const [ocupadas, setOcupadas] = useState(0);
  const [enLimpieza, setEnLimpieza] = useState(0);

  useEffect(() => {
    document.title = "Habitaciones";
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      if (parsedUserData.length > 0) {
        set_ide_eje(parsedUserData[0].ide_eje);
      }
    }
  }, []);

  useEffect(() => {
    if (ide_eje) {
      ConsultaPisos(ide_eje);
    }
  }, [ide_eje]);

  useEffect(() => {
    if (pisoSeleccionado >= 0) {
      ConsultaHabitaciones(ide_eje, pisoSeleccionado);
    }
  }, [pisoSeleccionado]);

  const ConsultaPisos = async (ide_eje) => {
    try {
      const requestData = { ide_eje: ide_eje };
      const response = await fetch(`${url}/post_pisos`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      if (data.success && data.data) {
        setPisos(data.data);
        if (data.data.length > 0) {
          setPisoSeleccionado(data.data[0].ide_pis);
        }
      } else {
        setPisos([]);
      }
    } catch (error) {
      setPisos([]);
      console.error("Error al obtener los pisos:", error.message);
    }
  };

  const ConsultaHabitaciones = async (ide_eje, ide_pis) => {
    try {
      const requestData = { ide_eje: ide_eje, ide_pis: ide_pis };
      const response = await fetch(`${url}/post_vw_habitacion_alquiler`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      if (data.success && data.data) {
        setHabitaciones(data.data);
        contarHabitacionesPorEstado(data.data);
      } else {
        setHabitaciones([]);
        resetContadores();
      }
    } catch (error) {
      setHabitaciones([]);
      resetContadores();
      console.error("Error al obtener las habitaciones:", error.message);
    }
  };

  const contarHabitacionesPorEstado = (habitaciones) => {
    let disponibles = 0;
    let ocupadas = 0;
    let enLimpieza = 0;

    habitaciones.forEach((habitacion) => {
      if (habitacion.est_hab === 1) disponibles += 1;
      if (habitacion.est_hab === 2) ocupadas += 1;
      if (habitacion.est_hab === 3) enLimpieza += 1;
    });

    setDisponibles(disponibles);
    setOcupadas(ocupadas);
    setEnLimpieza(enLimpieza);
  };

  const resetContadores = () => {
    setDisponibles(0);
    setOcupadas(0);
    setEnLimpieza(0);
  };

  const handleChangePiso = (event) => {
    setPisoSeleccionado(event.target.value);
  };
  ConsultaHabitaciones(ide_eje,pisoSeleccionado)
 
  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>Habitaciones Disponibles</Typography>

      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <FormControl fullWidth>
            <InputLabel id="piso-select-label">Filtrar por Piso</InputLabel>
            <Select
              labelId="piso-select-label"
              value={pisoSeleccionado}
              onChange={handleChangePiso}
              label="Filtrar por Piso"
              size="small"
            >
              {pisos.length > 0 ? (
                pisos.map((piso) => (
                  <MenuItem key={piso.ide_pis} value={piso.ide_pis}>
                    {piso.des_pis}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No hay pisos disponibles</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={8} md={9} lg={10}>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: '#00A885', color: '#fff', width: '150px', height: '50px' }}
              >
                Disponibles: {disponibles}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: '#f44336', color: '#fff', width: '150px', height: '50px' }}
              >
                Ocupadas: {ocupadas}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: '#00bcd4', color: '#fff', width: '150px', height: '50px' }}
              >
                Limpieza: {enLimpieza}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="flex-start">
        {habitaciones.map((habitacion) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={habitacion.nro_hab}>
            <CustomTooltip
              title={
                <span
                style={{
                  fontSize: "16px", // Tamaño de letra
                  color: "#000", // Color del texto
                  lineHeight: "1", // Espaciado entre líneas
                  textAlign: "left", // Alinear texto a la izquierda
                }}
                  dangerouslySetInnerHTML={{
                    __html: habitacion.dat_alq_det
                      ? habitacion.dat_alq_det.replace(/\*/g, "<br/>")
                      : "No hay detalles disponibles",
                  }}
                />
              }
              arrow
            >
              <Card
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  backgroundColor: getColorByEstado(habitacion.est_hab),
                  color: '#fff',
                  borderRadius: '10px',
                  height: '130px',
                  cursor: 'pointer',
                }}
              >
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex' }}>
                    <LocalHotelIcon style={{ height: '50px', width: '50px' }} />
                    <Typography component="div" align="center" style={{ margin: '-7px 12px 0 50px', fontSize: "35px" }}>
                      {habitacion.nro_hab}
                    </Typography>
                  </div>
                  <Typography variant="body1" component="div" align="center" style={{fontSize:"13px"}}>
                    {habitacion.des_t_h}
                  </Typography>
                  <Typography variant="body2" component="div" align="center"style={{fontSize:"11px"}}>
                    Precio S/.: {habitacion.pre_hab}
                  </Typography>
                  <Typography variant="body2" align="left"  style={{fontSize:"10px"}}>
                    {habitacion.nom_com}
                  </Typography>
                </CardContent>
              </Card>
            </CustomTooltip>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Habitaciones;
