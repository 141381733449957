import * as React from 'react';
import { Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import FormatListBulletedTwoToneIcon from '@mui/icons-material/FormatListBulletedTwoTone';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import BedTwoToneIcon from '@mui/icons-material/BedTwoTone';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalMallIcon from '@mui/icons-material/LocalMall';
export const mainListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Opciones
    </ListSubheader>
    <ListItemButton component={Link} to="/habitaciones">
      <ListItemIcon>
        <NightShelterIcon/>
      </ListItemIcon>
      <ListItemText primary="Habitaciones" />
    </ListItemButton>
    <ListItemButton component={Link} to="/dashboard">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton component={Link} to="/comprobantes">
      <ListItemIcon>
        <FormatListBulletedTwoToneIcon />
      </ListItemIcon>
      <ListItemText primary="Comprobantes" />
    </ListItemButton>
    <ListItemButton component={Link} to="/alquilerhab">
      <ListItemIcon>
        <BedTwoToneIcon />
      </ListItemIcon>
      <ListItemText primary="Alquiler Habitación" />
    </ListItemButton>
    <ListItemButton component={Link} to="/ventas">
      <ListItemIcon>
        <ShoppingCartCheckoutIcon/>
      </ListItemIcon>
      <ListItemText primary="Venta de Productos" />
    </ListItemButton>
    <ListItemButton component={Link} to="/ingresosdiarios">
      <ListItemIcon>
        <MonetizationOnIcon/>
      </ListItemIcon>
      <ListItemText primary="Cierre Diario" />
    </ListItemButton>
    <ListItemButton component={Link} to="/stocks">
      <ListItemIcon>
        <LocalMallIcon/>
      </ListItemIcon>
      <ListItemText primary="Stocks de Productos" />
    </ListItemButton>
    
    {/*
    <ListItemButton component={Link} to="/dashboard/reports">
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItemButton>
    <ListItemButton component={Link} to="/dashboard/integrations">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
</ListItemButton>*/}
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Mas Opciones
    </ListSubheader>
    {/*<ListItemButton component={Link} to="/dashboard/current-month">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton component={Link} to="/dashboard/last-quarter">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton component={Link} to="/dashboard/year-end-sale">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
</ListItemButton>*/}
  </React.Fragment>
);
