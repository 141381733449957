import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Button, Grid, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import CustomModal from "../CustomModal";
import { url } from "../Config";

const months = [
  { label: 'Enero', id: 1 },
  { label: 'Febrero', id: 2 },
  { label: 'Marzo', id: 3 },
  { label: 'Abril', id: 4 },
  { label: 'Mayo', id: 5 },
  { label: 'Junio', id: 6 },
  { label: 'Julio', id: 7 },
  { label: 'Agosto', id: 8 },
  { label: 'Septiembre', id: 9 },
  { label: 'Octubre', id: 10 },
  { label: 'Noviembre', id: 11 },
  { label: 'Diciembre', id: 12 },
];

function GraficoIngresosHab() {
  const [modalMessage, setModalMessage] = useState({ type: "", text: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [diaAlq, setDiaAlq] = useState([]);
  const [preDia, setPreDia] = useState([]);
  const [ide_eje, set_ide_eje] = useState('');

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      if (parsedUserData.length > 0) {
        set_ide_eje(parsedUserData[0].ide_eje);
      }
    }
    const currentMonthId = new Date().getMonth() + 1;
    const currentMonth = months.find(month => month.id === currentMonthId);
    setSelectedMonth(currentMonth);
  }, []);

  useEffect(() => {
    if (ide_eje) {
      ConsultaGrafico(year, selectedMonth);
    }
  }, [ide_eje]);

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event, value) => {
    setSelectedMonth(value);
  };

  const ConsultaGrafico = async (ano_eje, mes_eje) => {
    if (ano_eje && mes_eje) {
      try {
        const requestData = { ide_eje: ide_eje, ano_eje: ano_eje, mes_eje: mes_eje.id };
        console.log(requestData);
        const response = await fetch(`${url}/post_dashboard_vw_alquiler_det_ingresos_mensuales`, {
          method: "POST",
          referrerPolicy: "unsafe-url",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestData),
        });

        const datas = await response.json();
        if (datas.success && datas.data) {
          if (datas.data[0].dia_alq && datas.data[0].pre_dia)  {
            setDiaAlq(datas.data[0].dia_alq || []); // Asegúrate de que es un array
            setPreDia(datas.data[0].pre_dia || []); // Asegúrate de que es un array
          } else {
            setDiaAlq([]);
            setPreDia([]);
            setModalMessage({ type: "warning", text: "No se encontraron datos." });
            setIsModalOpen(true);
          }
        } else {
          setDiaAlq([]);
          setPreDia([]);
          setModalMessage({ type: "warning", text: "No se encontraron datos." });
          setIsModalOpen(true);
        }
      } catch (error) {
        setDiaAlq([]);
        setPreDia([]);
        setModalMessage({ type: "error", text: "Error: " + error.message });
        setIsModalOpen(true);
      }
    } else {
      setModalMessage({ type: "warning", text: "Por favor, ingresa el año y selecciona un mes" });
      setIsModalOpen(true);
    }
  };

  const ClickConsultaGrafico = () => {
    ConsultaGrafico(year, selectedMonth);
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }} textAlign="center" mb={1}>
        Grafico de Ingresos de Alquiler Diario
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            value={year}
            onChange={handleYearChange}
            type="number"
            inputProps={{ min: 0 }}
            label="Año"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            fullWidth
            disablePortal
            id="combo-box-demo"
            options={months}
            value={selectedMonth} // Valor actual del mes seleccionado
            getOptionLabel={(option) => option.label}
            onChange={handleMonthChange}
            renderInput={(params) => <TextField {...params} fullWidth label="Mes" />}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button fullWidth variant="contained" onClick={ClickConsultaGrafico}>
            Consultar
          </Button>
        </Grid>
      </Grid>
     
        <BarChart
          xAxis={[{ scaleType: 'band', data: diaAlq }]}
          series={[{ data: preDia }]}
          height={205}
        />

      {/*<CustomModal isOpen={isModalOpen} toggle={toggleModal} message={modalMessage} />*/}
    </>
  );
}

export default GraficoIngresosHab;
