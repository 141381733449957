import React, { useState , useEffect } from 'react';
import { Typography, TextField, Button, Container, Checkbox, Paper, Box } from '@mui/material';
import MapsHomeWorkTwoToneIcon from '@mui/icons-material/MapsHomeWorkTwoTone';
import { useNavigate } from 'react-router-dom';
import { url } from './Config';
import CustomModal from "./CustomModal";

const containerStyle = {
  marginTop: '-50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderRadius: '10px', // Borde redondeado
  border: '1px solid #ccc', // Borde con color
  backgroundColor: 'white', // Fondo blanco para el Paper
  zIndex: 2, // Asegura que el Paper esté por encima del fondo animado
  position: 'relative', // Necesario para el zIndex
};

const formStyle = {
  width: '85%',
  marginTop: '1rem',
};

const submitButtonStyle = {
  margin: '0 0 2rem',
};

const Login = () => {
  useEffect(() => {
    document.title = "login";
  }, []);
  const [modalMessage, setModalMessage] = useState({ type: "", text: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ruc_eje, setruc_eje] = useState('');
  const [nom_usu, setnom_usu] = useState('');
  const [pwd_usu, setpwd_usu] = useState('');
  const [rememberUser, setRememberUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate  = useNavigate();

  useEffect(() => {
    // Recuperar los datos de localStorage cuando el componente se monta
    const save_ruc_eje        = localStorage.getItem('save_ruc_eje');
    const save_nom_usu        = localStorage.getItem('save_nom_usu');
    const savedRememberUser   = localStorage.getItem('rememberUser') === 'true';
    
    if (save_ruc_eje) setruc_eje(save_ruc_eje);
    if (save_nom_usu) setnom_usu(save_nom_usu);
    setRememberUser(savedRememberUser);
  }, []);

  const ValidacionNumericos = (e) => {
    const value = e.target.value;
    // Solo permite caracteres numéricos
    if (/^\d*$/.test(value)) {
      setruc_eje(value);
    }
  };

  const ValidacionMayuscula = (e) => {
    // Solo permite mayusculas
    const value = e.target.value.toUpperCase();
    setnom_usu(value);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const DatosLogin = async (e) => {
    e.preventDefault();

    const requestData = {
      ruc_eje,
      nom_usu,
      pwd_usu,
    };
    //console.log(JSON.stringify(requestData));
    try {
      const response = await fetch(`${url}/post_vw_login`, {
        
        method: 'POST',
        referrerPolicy: "unsafe-url" ,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      //console.log(data.data);
      if (data.success && data.data) {
        // Guarda los datos del usuario en localStorage para usarlos en otras páginas
        localStorage.setItem('userData', JSON.stringify(data.data));
        //console.log("datos que se gurdadn",JSON.stringify(data.data));
        // Guarda el RUC y el nombre de usuario si "Recordar usuario" está marcado
        if (rememberUser) {
          localStorage.setItem('save_ruc_eje', ruc_eje);
          localStorage.setItem('save_nom_usu', nom_usu);
        } else {
          localStorage.removeItem('save_ruc_eje');
          localStorage.removeItem('save_nom_usu');
        }
        // Guarda el estado de "Recordar usuario"
        localStorage.setItem('rememberUser', rememberUser.toString());
        // Redirige a la página de inicio u otra página
        navigate('/habitaciones');
      } else {
        setModalMessage({ type: "error", text: data.error.message });
        setIsModalOpen(true);
      }
    } catch (error) {
      setModalMessage({ type: "error", text: "Error fetching user data: " + error.message });
      setIsModalOpen(true);
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:before': {
          content: '""',
          background: 'radial-gradient(circle, #d2f1df, #d3d7fa, #bad8f4)',
          backgroundSize: '400% 400%',
          animation: 'gradient 15s ease infinite',
          position: 'absolute',
          height: '100%',
          width: '100%',
          zIndex: 1,
        },
      }}
    >
      <Container component={Paper} elevation={3} maxWidth="xs" style={containerStyle} >
      <MapsHomeWorkTwoToneIcon style={{ height: '70px', width: '70px' }} />
        <Typography component="h1" variant="h4" align="center">
          Sistema de Administración Hotelera
        </Typography>
        <form style={formStyle} onSubmit={DatosLogin} >
          <TextField
             variant="outlined"
             margin="normal"
             required
             fullWidth
             id="ruc_eje"
             label="RUC"
             name="ruc_eje"
             autoComplete="ruc_eje"
             type="text"
             inputMode="numeric"
             autoFocus
             value={ruc_eje}
             onChange={ValidacionNumericos}  
          />  
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="nom_usu"
            label="Nombre de usuario"
            name="nom_usu"
            autoComplete="nom_usu"
            value={nom_usu}
            onChange={ValidacionMayuscula}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="pwd_usu"
            label="Contraseña"
            type="password"
            id="pwd_usu"
            value={pwd_usu}
            onChange={(e) => setpwd_usu(e.target.value)}
          />
          <div>
            <Checkbox
              id="rememberUser"
              checked={rememberUser}
              onChange={(e) => setRememberUser(e.target.checked)}
            />
            <label htmlFor="rememberUser">Recordar usuario</label>
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={submitButtonStyle}
          >
            Iniciar sesión
          </Button>
        </form>
      </Container>
      <CustomModal isOpen={isModalOpen} toggle={toggleModal} message={modalMessage} />
    </Box>
   
  );
};

export default Login;
